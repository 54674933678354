<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <div class="content-box">
      <el-row class="search-box">
        <el-row class="search-row">
          <el-row class="search-item">
            <label>卡片状态：</label>
            <el-select v-model="params.state">
              <el-option v-for="(val, key) in dictData['card-status']" :key="key" :label="val" :value="key"></el-option>
            </el-select>
          </el-row>
          <el-row class="search-item">
            <label>卡片有效期：</label>
            <date-packer :datePacker="params.dateRange" @setDatePacker="getDatePacker" format="yyyy-MM-dd"></date-packer>
          </el-row>
          <el-row class="search-item">
            <label>姓名：</label>
            <el-input placeholder="姓名"></el-input>
          </el-row>
          <el-button @click="handleQuery(true)" type="primary">搜索</el-button>
          <el-button @click="handleQuery(false)">重置</el-button>
        </el-row>
      </el-row>
      <el-row class="table-box">
        <el-row class="m-bottom-10" type="flex" justify="space-between">
          <el-row></el-row>
          <el-row class="right-btn">
            <el-button @click="action === 'add'; visible = true" class="bg-gradient" type="primary">添加员工</el-button>
          </el-row>
        </el-row>
        <el-table v-loading="loading" :data="tableData" :stripe="true" border fit>
          <el-table-column label="序号">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column prop="realName" label="姓名"></el-table-column>
          <el-table-column prop="duty" label="职责"></el-table-column>
          <el-table-column prop="remark" label="备注"></el-table-column>
          <el-table-column label="卡片状态">
            <template slot-scope="scope">{{ scope.row.state | filterState(that) }}</template>
          </el-table-column>
          <el-table-column label="房间权限">
            <template slot-scope="scope">{{ scope.row.permissionsType }}</template>
          </el-table-column>
          <el-table-column prop="failureTime" label="卡片截止有效期"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="handleSee(scope.row.id)" type="text">查看</el-button>
              <el-button @click="handleEdit(scope.row)" type="text">编辑</el-button>
              <el-button v-if="scope.row.state === 'EFFECTIVE'" @click="handleDisable(scope.row.id)" type="text">
                <el-link type="danger">禁用</el-link>
              </el-button>
              <el-button v-if="scope.row.state === 'DISABLE'" @click="handleEffective(scope.row.id)" type="text">
                <el-link type="danger">启用</el-link>
              </el-button>
              <el-button v-if="scope.row.state === 'NO_BUSINESS_CARD_PRINTING'" type="text">
                <el-link type="danger">发卡</el-link>
              </el-button>
              <el-button type="text">
                <el-link @click="handleDel(scope.row.id)" type="danger">删除</el-link>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePageNum" @handleCurrentChangeSub="handleCurrPage"/>
      </el-row>
    </div>
    <el-dialog :title="`${action === 'add' ? '添加' : '编辑'}员工卡`" :before-close="handleClose" :visible.sync="visible"
               width="650px" :close-on-click-modal="false">
      <el-form :model="ruleForm" :rules="rules" ref="form">
        <el-form-item label="姓名" prop="realName">
          <el-input v-model="ruleForm.realName" class="width-280" placeholder="姓名"></el-input>
        </el-form-item>
        <el-form-item label="职责">
          <el-input v-model="ruleForm.duty" class="width-280" placeholder="职责"></el-input>
        </el-form-item>
        <el-form-item label="卡片有效期">
          <el-radio-group v-model="ruleForm.validityType">
            <el-radio label="MONTH">一个月</el-radio>
            <el-radio label="YEAR">一年</el-radio>
            <el-radio label="LONGTIME">长期</el-radio>
            <el-radio label="CUSTOM">自定义</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="房间权限">
          <el-radio-group v-model="ruleForm.permissionsType">
            <el-radio label="ROOM">选择房间</el-radio>
            <el-radio label="FLOOR">选择楼层</el-radio>
            <el-radio label="ALL">全选</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="ruleForm.remark" class="width-280" type="textarea" placeholder="备注"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
          <el-button @click="handleSave" type="primary">确定</el-button>
          <el-button @click="visible = false">取消</el-button>
        </span>
    </el-dialog>
    <el-dialog title="员工卡详情" :visible.sync="visibleDetail" width="650px">
      <el-descriptions :column="2">
        <el-descriptions-item label="姓名">{{ details.realName }}</el-descriptions-item>
        <el-descriptions-item label="职责">{{ details.duty }}</el-descriptions-item>
        <el-descriptions-item label="卡片有效期">{{ details.failureTime }}</el-descriptions-item>
        <el-descriptions-item label="房间权限">{{ details.permissionsType }}</el-descriptions-item>
        <el-descriptions-item label="备注">{{ details.remark }}</el-descriptions-item>
      </el-descriptions>
      <span slot="footer">
          <el-button @click="visibleDetail = false">取消</el-button>
        </span>
    </el-dialog>
  </div>
</template>
<script>
import { getPage, addStaffCard, delStaffCard,
  setDisable, setEffective, seeDetail } from '@/api/pms/sendCard/staffCard';
import getDict from "@/utils/dataDict";
import { mapState } from 'vuex';
export default {
  name: 'staffCard',
  data() {
    return {
      crumbs: new Map([
          ['PMS'], ['发卡管理'], ['员工发卡']
      ]),
      that: this,
      params: {
        state: '',
        dateRange: [],
      },
      loading: false,
      tableData: [],
      total: 0,
      limit: 0,
      page: 1,
      visible: false,
      ruleForm: {
        id: '',
        realName: '',
        duty: '',
        validityType: 'MONTH',
        permissionsType: 'ROOM',
        remark: ''
      },
      rules: {
        realName: [{ required: true, message: '姓名不能为空！' }]
      },
      action: 'add',
      visibleDetail: false,
      details: {}
    }
  },
  computed: {
    ...mapState(['hotelInfo', 'dictData'])
  },
  mounted() {
    getDict('card-status')
    this.initForm = { ...this.ruleForm };
    this.limit = sessionStorage.getItem('pageSize') * 1;
    this.getStaffCardList()
  },
  methods: {
    // 获取员工卡列表
    getStaffCardList() {
      const query = `?limit=${ this.limit }&page=${ this.page }`,
            params = {
              hotelId: this.hotelInfo.id,
              state: this.params.state,
              beginTime: this.params.dateRange[0],
              endTime: this.params.dateRange[1]
            };
      getPage(query, params).then(({ success, records, total }) => {
        if (success) {
          this.loading = false
          this.total = total
          this.tableData = records
        }
      })
    },
    // 查询发卡列表
    handleQuery(bool) {
      this.page = 1
      if (bool) {
        this.getStaffCardList()
      } else {
        this.params.state = ''
        this.params.dateRange = []
      }
    },
    // 改变日期
    getDatePacker(val) {
      this.dateRange = val
    },
    // 改变每页数
    changePageNum(num) {
      this.limit = num
      this.getStaffCardList(num)
    },
    // 改变当前页
    handleCurrPage(num) {
      this.page = num
      this.getStaffCardList()
    },
    // 编辑员工卡
    handleEdit(row) {
      this.visible = true
      this.action = 'edit'
      for (const k in this.ruleForm) {
        k in row && (this.ruleForm[k] = row[k])
      }
    },
    // 保存员工卡
    handleSave() {
      this.$refs.form.validate(valid => {
        if (!valid) return
        const params = { ...this.ruleForm, hotelId: this.hotelInfo.id };
        this.action === 'add' && delete params.id
        addStaffCard(this.action, params).then(({ success }) => {
          if (success) {
            this.handleClose()
            this.getStaffCardList()
            this.$message({ message: '添加成功！', type: 'success' })
          }
        })
      })
    },
    // 关闭弹窗
    handleClose() {
      this.visible = false
      this.ruleForm = { ...this.initForm }
    },
    // 删除员工卡
    handleDel(id) {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delStaffCard(`/${ id }`).then(({ success }) => {
          if (success) {
            this.getStaffCardList()
            this.$message({ message: '删除成功！', type: 'success' })
          }
        })
      })
    },
    // 禁用员工卡
    handleDisable(id) {
      this.$confirm('确定禁用？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        setDisable(`/${ id }`).then(({ success }) => {
          if (success) {
            this.getStaffCardList()
            this.$message({ message: '禁用成功！', type: 'success' })
          }
        })
      })
    },
    // 禁用员工卡
    handleEffective(id) {
      this.$confirm('确定启用？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        setEffective(`/${ id }`).then(({ success }) => {
          if (success) {
            this.getStaffCardList()
            this.$message({ message: '启用成功！', type: 'success' })
          }
        })
      })
    },
    // 查看员工卡详情
    handleSee(id) {
      seeDetail(`/${ id }`).then(({ success, records }) => {
        if (success) this.visibleDetail = true; this.details = records
      })
    }
  },
  filters: {
    filterState(val, that) {
      if (val && that.dictData['card-status']) return that.dictData['card-status'][val] || val
    }
  }
}
</script>
<style lang="scss" scoped>
.cont {
  .el-form {
    padding: 0 0 0 30px;
    ::v-deep .el-form-item__label { width: 84px }
    ::v-deep .el-form-item__error { margin-left: 84px }
  }
}
</style>
