/*
 * 获取数据字典
 * dictVal *隐藏值 支持array和string类型
 * isSimple 简单模式
 * filterItem 需要过滤的字典内容
 * 格式为{字典编码}@{任意字符(当然取名能与当前页面业务相符是最好的)}
 * {'order-source@reserve':["SSM","APP"]}这个代表需要过滤掉order-source这个字典中的"SSM","APP"这两个字典值
*/
import { system } from "@/api/interface/system";
import axios from "@/api/request";
import store from "@/store";

export default function getDict(dictVal, isSimple = true, filterItem) {
   if (typeof dictVal !== 'string' && dictVal.constructor !== Array) {
      throw new Error(`参数类型错误：dictVal->${dictVal}`)
   }
   if (typeof isSimple !== 'boolean') {
      throw new Error(`参数类型错误：isSimple->${isSimple}`)
   }
   // 从缓存中获取字典列表
   let dictValArr = []
   let dictData = JSON.parse(sessionStorage.getItem('dictData') || '{}')
   // 隐藏值为字符串类型
   // typeof dictVal === 'string' && dictVal && (dictVal in dictData) || dictValArr.push(dictVal)
   if (typeof dictVal === 'string' && dictVal && !(dictVal in dictData)) dictValArr.push(dictVal)
   // 隐藏值为数组类型
   if (dictVal.constructor === Array && dictVal.length !== 0) {
      // 如果缓存中已存在该字典 则从数组中移除（移除后该字典不在参与接口的请求）
      dictValArr = dictVal
      for (let i = dictValArr.length - 1; i >= 0; i --) {
         if (isSimple) dictValArr[i] in dictData && dictValArr.splice(i, 1)
         else (dictValArr[i] + '-list') in dictData && dictValArr.splice(i, 1)
      }
   }
   // 发起请求并保存在缓存中
   return new Promise( (resolve, reject) => {
      if (dictValArr.length > 0) {
         const url = system.subDict
         const param = { numbers: dictValArr, isSimple }
         axios.post(url, param, 'json', { voidUdata: true }).then(res => {
            if (res.success){
               let data = res.records
               if (Object.keys(data).length === 0) resolve(true)
               for (let key in data) {
                  // 如果有字典值需要过滤，哪这个if代码逻辑是专门处理这种过滤情况的
                  for(let item in filterItem){
                     if(key === item.split('@')[0]){
                        if(isSimple){
                           dictData[item] = Object.keys(data[key]).reduce(function(r, e) {
                              if (!filterItem[item].includes(e)){
                                 r[e] = data[key][e];
                              }
                              return r;
                           }, {});
                        } else {
                           dictData[item + '-list'] = data[key].map(value => {
                              if(!filterItem[item].find(v => v === value.dictKey)){
                                 return { label: value.dictVal, value: value.dictKey }
                              }
                              // 去掉null的元素
                           }).filter(v => v);
                        }
                     }
                  }
                  if (isSimple) {
                     dictData[key] = data[key];
                  }else {
                     dictData[key + '-list'] = data[key].map(item => {
                        return { label: item.dictVal, value: item.dictKey }
                     })
                  }
               }
               store.dispatch('setDictData', dictData)
               sessionStorage.setItem('dictData', JSON.stringify(dictData))
            }
            resolve(true)
         })
            
      } else {
         resolve(true)
         store.dispatch('setDictData', dictData)
      }
   })
}
