import request from "@/api/service";

const api = {
   page: '/pms/employeeCard/getPage',
   add: '/pms/employeeCard/add',
   edit: '/pms/employeeCard/edit',
   delete: '/pms/employeeCard/delete',
   disable: '/pms/employeeCard/setDisable',
   effective: '/pms/employeeCard/setEffective',
   detail: '/pms/employeeCard/detail'
}

export function getPage(query, params) {
   return request({
      url: api.page + query,
      method: 'POST',
      data: params
   })
}

export function addStaffCard(action, params) {
   return request({
      url: api[action],
      method: 'POST',
      data: params
   })
}

export function delStaffCard(params) {
   return request({
      url: api.delete + params,
      method: 'GET',
   })
}

export function setDisable(params) {
   return request({
      url: api.disable + params,
      method: 'GET'
   })
}

export function setEffective(params) {
   return request({
      url: api.effective + params,
      method: 'GET'
   })
}

export function seeDetail(params) {
   return request({
      url: api.detail + params,
      method: 'GET'
   })
}
